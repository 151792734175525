<template>
  <v-app>
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>

<script>
import {getJson} from "../firebase-remote";

export default {
  name: "App",
  data() {
    return {};
  },
  async created() {
    let obj = await getJson(process.env.VUE_APP_CONFIG_KEY)
    document.title = obj.indexTitle
  }
};//
</script>
