<template>
	<v-layout wrap justify-start>
		<v-flex xs12 pt-12>
			<v-layout wrap justify-center v-if="json != null">
				<v-flex py-4>
          <Card :title="card.title"  :text="card.text" :btn="card.btn" :btnType="'cambia'" @activeForm="activeForm" :page="'form'"></Card>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex xs12 text-center py-12 >
			<span class="subscription-title">DATI PER SOTTOSCRIZIONE POLIZZA</span>
		</v-flex>
		<v-flex xs12 :key="$route.fullPath">
			<NewForm />
		</v-flex>
    <v-dialog
        v-model="showForm"
        persistent
        max-width="600px"
    >
      <v-card>
        <!--				<v-card-title>-->
        <!--					<span class="text-h5">User Profile</span>-->
        <!--				</v-card-title>-->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Nome*"
                    v-model="form.name"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Cognome*"
                    v-model="form.surname"
                    hint="example of helper text only on focus"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Email*"
                    v-model="form.email"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Cellulare*"
                    v-model="form.phone"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campi obbligatori</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="showForm = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="addDoc"
          >
            Invia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	</v-layout>

</template>

<script>
// import _ from "lodash";
import Card from "@/components/Card";
import NewForm from "@/components/subscription/NewForm";
import {getJson, postInfoForm} from "../../firebase-remote";

export default {
	name: "Subscription",
	components: {
		Card,
		//Company,
		//Individual
		NewForm
	},
	data() {
		return {
      json: null,
      showForm: false,
      form: {}
		}
	},
	async created() {
    await this.getData()
    // let slug = this.$route.params.slug
		// this.index = _.findIndex(this.cardItems, function(elm) {return elm.slug === slug })
	},
	computed: {
    card() {
      return this.json.card[this.$route.params.name]
    },
	},
  methods: {
    async getData() {
      this.json = await getJson(process.env.VUE_APP_CONFIG_KEY)
    },
    async success(values) {
      //router.push({ path: '/success' })
      console.log(values)
      let obj = values
      switch (this.formType) {
        case 0:
          obj.formType = "Ditta individuale"
          break
        case 1:
          obj.formType = "Società di capitale"
          break
        case 2:
          obj.formType = "Società di persone"
      }
      obj.formType = this.formType

    },
    activeForm() {
      console.log("test")
      // this.activeItem = n
      this.showForm = true
    },
    async addDoc() {
      await postInfoForm(this.form)
      this.showForm = false
    },
  }
}
</script>

<style scoped>
.subscription-title {
	font-family: 'Montserrat', sans-serif;
	color: #204177;
	font-size: 26px;
	font-weight: 600;
}
.confirmation-message{
	color: #273B60;
	background: #D1E0FB 0% 0% no-repeat padding-box;
	padding: 50px;
	border-radius: 35px;
	margin-top: 100px;
	text-align: center;
}

</style>
