import {fetchAndActivate, getString} from "firebase/remote-config"
import {db, remoteConfig} from "@/firebase";
import {collection, addDoc} from "firebase/firestore";

export async function getJson(key) {
    let obj
    await fetchAndActivate(remoteConfig).then (() => {
        let json = getString(remoteConfig, key)
        obj = JSON.parse(json)
    })
    return obj
}

export async function postForm(form) {
    await addDoc(collection(db, process.env.VUE_APP_FIRESTORE_PATH), form);
    console.log(form)
}

export async function postInfoForm(form) {
    await addDoc(collection(db, process.env.VUE_APP_FORM_INFO), form);
    console.log(form)
}

