import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		cardItems: [
			{
				title: 'CATEGORIA BENESSERE',
				slug: 'categoria-benessere',
				description: 'La polizza di Responsabilità civile verso terzi tiene indenne il tuo centro estetico dai rischi connessi alla tua attività che portino a richieste di risarcimento per danni involontariamente causati ai tuoi clienti. Spesso le imprese non sono tutelate adeguatamente ed è per questo ancora più importante assicurarsi, garantendo serenità e tranquillità a te e ai tuoi collaboratori.',
				icon: 'people',
				value: '1',
				pdf: '/CGA_TUA Benessere.pdf',
				pdfSinistri: '/BENESSERE 1.pdf'
			},
			{
				title: 'CATEGORIA IMPIANTISTI',
				slug: 'categoria-impiantisti',
				description: 'Grazie alla polizza di Responsabilità civile verso terzi è possibile tutelarsi contro gli eventi negativi involontariamente causati nell’esercizio della tua attività di impiantista, garantendo serenità ai tuoi collaboratori e clienti.',
				icon: 'book',
				value: '2',
				pdf: '/CGA Amissima Impiantisti.pdf',
				pdfSinistri: '/IMPIANTISTI 1.pdf'
			},
			{
				title: 'CATEGORIA AUTORIPARATORI',
				slug: 'categoria-autoriparatori',
				description: 'L’assicurazione per autoriparatori permette di dare una copertura assicurativa a chi lavora nel mondo delle officine meccaniche e delle carrozzerie. Grazie alla polizza di Responsabilità civile verso terzi è possibile tutelarsi contro gli eventi negativi involontariamente causati garantendo serenità e tranquillità ai tuoi collaboratori e clienti.',
				icon: 'bot',
				value: '3',
				pdf: '/CGA Bene Autoriparatori.pdf',
				pdfSinistri: '/AUTORIPARATORI 1.pdf'

			},
		]
	},
	mutations: {},
	actions: {},
	getters: {}
});
