<template>
	<v-layout wrap justify-center class="footer-component" pa-3 pa-lg-12>
		<v-flex xs12 sm12 xl9>
			<v-layout wrap justify-space-around>
				<v-flex xs12 sm4 lg3>
					<span class="footer-item-title">Email</span>
					<br>
					<br>
<!--					<a href="mailto:parma@consulbrokers.it" class="footer-item-value">parma@consulbrokers.it</a><br>-->
					<a href="mailto: info@cbdigital.tech" class="footer-item-value"> info@cbdigital.tech</a>
				</v-flex>
				<v-flex xs12 sm4 lg3>
					<span class="footer-item-title">Telefono</span>
					<br>
					<br>
						<a href="tel:+0270102458" class="footer-item-value">0270102458</a>
						<br>
<!--						<a href="tel:+393470644194" class="footer-item-value">+39 347 0644194</a>-->
				</v-flex>
				<v-flex xs12 sm4 lg3>
					<span class="footer-item-title">Indirizzo</span>
					<br>
					<br>
					<p class="footer-item-value">C.so di Porta Nuova, 16 <br> 20121 Milano</p>
				</v-flex>
			</v-layout>
			<v-layout wrap justify-center py-6 pt-12>
				<v-flex xs12 text-center>
					<p class="footer-description">
						Il numero sarà attivo dal lunedì al venerdì nelle seguenti fasce orarie: 9:00 - 16:00 <br>
            CB Digital è di proprietà di ConsulbrokersDigital S.r.l. iscritta al registro R.U.I. n. B000180506 C.so di Porta Nuova, 16 - 20121 Milano P.IVA 01209750379 capitale sociale 40.000,00 euro Copyright © CB Digital 2019
					</p>
					<a href="https://www.consulbrokers.it/azienda/privacy-policy" class="footer-description" target="_blank">PRIVACY - GESTIONE RECLAMI</a>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
.footer-component {
	background-color: #D1E1FB25;
}

.footer-item-title {
	font-family: 'Montserrat', sans-serif;
	color: #273B60;
	font-size: 25px;
	font-weight: 600;
}

.footer-item-value {
	font-family: 'Montserrat', sans-serif;
	color: #273B60;
	font-size: 20px;
	font-weight: 500;
}

.footer-description {
	font-family: 'Open Sans', sans-serif;
	color: #273B60;
	font-size: 16px;
	font-weight: 400;
}

p.footer-description {
	line-height: 30px;
}
</style>
