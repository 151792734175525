<template>
	<v-layout wrap justify-start>

		<v-flex xs12>
<!--			<v-flex my-15>-->
<!--				<Card :title="cardItems[index].title" :description="cardItems[index].description" :icon="cardItems[index].icon" :value="cardItems[index].value" :btn="'home'" />-->
<!--			</v-flex>-->
			<v-flex my-15>
				<div class="confirmation-message">Grazie per aver effettuato la richiesta, prenderemo in carico i tuoi dati e verrai ricontattato con l'offerta a te dedicata.<br>
					Per ogni esigenza potete far riferimento ai seguenti contatti: +39 0521 219200 - parma@consulbrokers.it
				</div>
			</v-flex>
		</v-flex>
	</v-layout>
</template>

<script>
// import Card from "@/components/Card";
// import _ from "lodash";


export default {
	name: "Confirmation-Message",
	// components: {Card},
	data() {
		return {
			index: undefined
		}
	},
	created() {
		// let slug = this.$route.params.slug
		// this.index = _.findIndex(this.cardItems, function(elm) {return elm.slug === slug })
	},
	computed: {
		cardItems() {
			return this.$store.state.cardItems
		}
	},
}
</script>


<style scoped>

.confirmation-message{
	color: #273B60;
	background: #D1E0FB 0% 0% no-repeat padding-box;
	padding: 50px;
	border-radius: 35px;
	margin-top: 100px;
	text-align: center;
}

</style>
