<template>
	<div>
		<v-layout wrap justify-center pt-6 v-if="$vuetify.breakpoint.mdAndDown">
			<v-flex xs12 pt-6>
				<v-card color="#3D5C70" style="border-radius: 0 50px;" flat>
					<v-layout wrap justify-center>
						<v-flex xs11 py-2 >
							<span class="home-heading">
								Consulenza per la gestione
								dei rischi moderna e dinamica
							</span>
						</v-flex>
					</v-layout>
				</v-card>
			</v-flex>
			<v-flex xs10 sm6 pt-6 pt-sm-12>
				<v-card rounded="xl" height="400px" width="350px" style="box-shadow: 35px 11px 99px #090E1429;">
					<v-img src="@/assets/girl.svg" contain height="400px"></v-img>
				</v-card>
			</v-flex>
			<v-flex xs12 sm6>
				<v-layout wrap justify-start py-12>
					<v-flex xs1 pt-2>
						<v-img src="@/assets/icons/circle-tick.svg" height="40px" contain></v-img>
					</v-flex>
					<v-flex xs11 pl-2 pr-10 text-justify>
						<p class="home-description">
              Una profonda conoscenza del mercato assicurativo e un’attenta analisi dei rischi dei clienti consentono di minimizzare le conseguenze economiche degli eventi dannosi, preservando il patrimonio, in termini di risorse umane e materiali.
						</p>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-layout wrap justify-start pt-12 v-if="$vuetify.breakpoint.lgAndUp && json != null">
			<v-flex xs12 pt-10>
				<v-card color="#3D5C70" style="border-radius: 0 122px;" flat>
					<v-layout wrap justify-center>
						<v-flex xs10 py-2>
							<span class="home-heading">
								Consulenza per la gestione<br>
								dei rischi moderna e dinamica
							</span>
						</v-flex>
					</v-layout>
				</v-card>
			</v-flex>
			<v-flex xs10>
				<v-layout wrap justify-start py-12>
					<v-flex xs1 offset-1 pt-2>
						<v-img src="@/assets/icons/circle-tick.svg" height="40px" contain></v-img>
					</v-flex>
					<v-flex xs6>
						<p class="home-description">
							Innovative tecniche di risk management che
							consentono attraverso un’opera di analisi di
							minimizzare le conseguenze economiche degli
							eventi dannosi, preservando il patrimonio, in
							termini di risorse umane e materiali.
						</p>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex xs12 style="position: absolute;top: 135px;left: 60%">
				<v-card rounded="xl" height="400px" width="350px" style="box-shadow: 35px 11px 99px #090E1429;">
					<v-img src="@/assets/girl.svg" contain style="position: absolute;top: -55px;height: 420px;"></v-img>
				</v-card>
			</v-flex>
		</v-layout>
		<v-layout wrap justify-center pt-6>
			<v-flex v-for="(card, i) in json.card" :key="i" py-4>
        <Card  :index="i" :title="card.title" :img="card.img" :text="card.text" :btn="card.btn" :page="'main'" @activeForm="activeForm"></Card>
			</v-flex>
		</v-layout>
		<v-layout wrap justify-center pt-12>
			<v-flex xs12 pt-6 text-justify>
				<span class="home-sub-description">
					Ecom tramite la sua partnership con Consulbrokers Spa mette a disposizione dei clienti un’attività di consulenza tesa a favorire una gestione dei rischi moderna e dinamica avvalendosi delle più innovative tecniche di risk management che consentono attraverso un’opera di analisi di minimizzare le conseguenze economiche degli eventi dannosi, preservando il patrimonio, in termini di risorse umane e materiali.
				</span>
			</v-flex>
		</v-layout>
		<v-dialog
			v-model="showForm"
			persistent
			max-width="600px"
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col
								cols="12"
								sm="6"
								md="4"
							>
								<v-text-field
									label="Nome*"
									v-model="form.name"
									required
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
							>
								<v-text-field
									label="Cognome*"
									v-model="form.surname"
									hint="example of helper text only on focus"
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-text-field
									label="Email*"
									v-model="form.email"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									label="Cellulare*"
									v-model="form.phone"
									required
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
					<small>*Campi obbligatori</small>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="showForm = false"
					>
						Close
					</v-btn>
					<v-btn
						color="blue darken-1"
						text
						@click="addDoc"
					>
						Invia
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Card from "@/components/Card";
import {getJson, postInfoForm} from "../../firebase-remote";

export default {
	components: {
		Card,
	},
	data() {
		return {
			showForm: false,
			activeItem: "",
      json: null,
			form: {
				name: '',
				surname: '',
				email: '',
				phone: ''
			}
		}
	},
	computed: {
		cardItems() {
			return this.$store.state.cardItems
		}
	},
  async created() {
    await this.getData()
  },
	methods: {
		activeForm(n) {
			this.activeItem = n
			this.showForm = true
		},
		async addDoc() {
			await postInfoForm(this.form)
			this.showForm = false
		},
    async getData() {
      this.json = await getJson(process.env.VUE_APP_CONFIG_KEY)
      console.log(this.json)
    }

	}
}
</script>
<style>
.home-heading {
	font-family: 'Montserrat', sans-serif;
	color: #FAFAFA;
	font-size: 28px;
	font-weight: 600;
	line-height: 32px;

}

.home-description {
	font-family: 'Montserrat', sans-serif;
	color: #273B60;
	font-size: 20px;
	font-weight: 600;
	line-height: 27px;
	text-align: left;
}

.home-sub-description {
	font-family: 'Montserrat', sans-serif;
	color: #15386F;
	font-size: 18px;
	font-weight: 400;
}

.form{
	position: absolute;
	bottom: 50vh;
	left: 50%;
	width: 40%;
	height: 40%;
	background-color: #ffffff;
	z-index: 99;
	-webkit-box-shadow: -1px -1px 18px 0px rgba(0,0,0,0.84);
	box-shadow: -1px -1px 18px 0px rgba(0,0,0,0.84);
}
</style>
