<template>
	<div>
<!--		<v-snackbar v-model="showSnackbar" right color="#204177">-->
<!--			{{ msg }}-->
<!--			<template v-slot:action="{ attrs }">-->
<!--				<v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">-->
<!--					<v-icon>mdi-close</v-icon>-->
<!--				</v-btn>-->
<!--			</template>-->
<!--		</v-snackbar>-->
		<v-layout wrap justify-start>
			<v-flex xs12 >
				<v-form ref="form" lazy-validation>
<!--		Ditta individuale-->
	<div v-if="dittaIndividuale">

					<v-layout wrap justify-start>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Forma societaria*</span>
							<v-select
								@change="isDittaIndividuale"
								:items="formTypes"
								v-model="subscriptionForm.formType"
								:rules="[rules.required]"
								ref="formType"
								class="custom-input"
								dense
								outlined
								color="#204177"
							></v-select>
						</v-flex>
					</v-layout>
					<v-layout wrap justify-start>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Ragione Sociale*</span>
							<v-text-field
								v-model="subscriptionForm.businessName"
								:rules="[rules.required]"
								ref="businessName"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">P.IVA</span>
							<v-text-field
								v-model="subscriptionForm.vatNumber"
								ref="vatNumber"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Codice fiscale proprietario</span>
							<v-text-field
								v-model="subscriptionForm.ownerTaxCode"
								ref="ownerTaxCode"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Data apertura</span>
							<v-text-field
								v-model="subscriptionForm.openingDay"
								ref="openingDay"
								type="date"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Numero addetti</span>
							<v-text-field
								v-model="subscriptionForm.numeroAddetti"
								ref="corporationSector"
								class="custom-input"
								outlined
								type="number"
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Data di nascita*</span>-->
<!--							<v-text-field-->
<!--								v-model="subscriptionForm.dob"-->
<!--								:rules="[rules.required]"-->
<!--								ref="dob"-->
<!--								class="custom-input"-->
<!--								outlined-->
<!--								dense-->
<!--								type="date"-->
<!--								color="#204177"-->
<!--							></v-text-field>-->
<!--						</v-flex>-->
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Nome*</span>
							<v-text-field
								v-model="subscriptionForm.firstName"
								:rules="[rules.required]"
								ref="firstName"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Cognome*</span>
							<v-text-field
								v-model="subscriptionForm.lastName"
								:rules="[rules.required]"
								ref="lastName"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
					</v-layout>
<!--					<v-layout wrap justify-start>-->
<!--						<v-flex xs12 px-2 pb-6>-->
<!--							<span class="subscritpion-item-heading">Luogo di nascita</span>-->
<!--						</v-flex>-->
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Nazione di nascita*</span>-->
<!--							<v-text-field-->
<!--								v-model="subscriptionForm.countryOfBirth"-->
<!--								:rules="[rules.required]"-->
<!--								ref="countryOfBirth"-->
<!--								class="custom-input"-->
<!--								outlined-->
<!--								dense-->
<!--								color="#204177"-->
<!--							></v-text-field>-->
<!--						</v-flex>-->
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Provincia di nascita*</span>-->
<!--							<v-select-->
<!--								:items="provinces"-->
<!--								v-model="subscriptionForm.provinceOfBirth"-->
<!--								:rules="[rules.required]"-->
<!--								ref="provinceOfBirth"-->
<!--								class="custom-input"-->
<!--								dense-->
<!--								outlined-->
<!--								color="#204177"-->
<!--								:return-object="true"-->
<!--								item-text="CDESCRIZIONE"-->
<!--							></v-select>-->
<!--						</v-flex>-->
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Città di nascita*</span>-->
<!--							<v-select-->
<!--								:items="citiesOfBirth"-->
<!--								v-model="subscriptionForm.cityOfBirth"-->
<!--								:rules="[rules.required]"-->
<!--								ref="cityOfBirth"-->
<!--								class="custom-input"-->
<!--								dense-->
<!--								outlined-->
<!--								color="#204177"-->
<!--								:return-object="true"-->
<!--								item-text="CNOMECOMUNE"-->
<!--							></v-select>-->
<!--						</v-flex>-->
<!--					</v-layout>-->
					<v-layout wrap justify-start>
						<v-flex xs12 px-2 pb-6>
							<span class="subscritpion-item-heading">Residenza</span>
						</v-flex>
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Nazione*</span>-->
<!--							<v-text-field-->
<!--								v-model="subscriptionForm.nation"-->
<!--								:rules="[rules.required]"-->
<!--								ref="nation"-->
<!--								class="custom-input"-->
<!--								outlined-->
<!--								dense-->
<!--								color="#204177"-->
<!--							></v-text-field>-->
<!--						</v-flex>-->
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Provincia*</span>
							<v-select
								:items="provinces"
								v-model="subscriptionForm.province"
								:rules="[rules.required]"
								ref="province"
								class="custom-input"
								dense
								outlined
								color="#204177"
								:return-object="true"
								item-text="CDESCRIZIONE"
							></v-select>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Città*</span>
							<v-select
								:items="cities"
								v-model="subscriptionForm.city"
								:rules="[rules.required]"
								ref="city"
								class="custom-input"
								dense
								outlined
								color="#204177"
								:return-object="true"
								item-text="CNOMECOMUNE"
							></v-select>
						</v-flex>
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Toponimo</span>-->
<!--							<v-select-->
<!--								:items="toponimos"-->
<!--								v-model="subscriptionForm.toponym"-->
<!--								ref="toponym"-->
<!--								class="custom-input"-->
<!--								dense-->
<!--								outlined-->
<!--								color="#204177"-->
<!--							></v-select>-->
<!--						</v-flex>-->
						<v-flex xs12 sm6 md6 lg8 px-2>
							<span class="pl-2 input-label">Indirizzo</span>
							<v-text-field
								v-model="subscriptionForm.address"
								ref="address"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
<!--						<v-flex xs12 sm6 md6 lg4 px-2>-->
<!--							<span class="pl-2 input-label">Numero civico</span>-->
<!--							<v-text-field-->
<!--								v-model="subscriptionForm.houseNo"-->
<!--								ref="houseNo"-->
<!--								class="custom-input"-->
<!--								outlined-->
<!--								dense-->
<!--								color="#204177"-->
<!--							></v-text-field>-->
<!--						</v-flex>-->
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">CAP</span>
							<v-text-field
								v-model="subscriptionForm.postalCode"
								ref="postalCode"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
					</v-layout>

					<v-layout wrap justify-start>
						<v-flex xs12 px-2 pb-6>
							<span class="subscritpion-item-heading">Contatto</span>
						</v-flex>
						<v-flex xs12 sm6 md6 lg4 px-2>
							<span class="pl-2 input-label">Cellulare*</span>
							<v-text-field
								v-model="subscriptionForm.mobilePhone"
								ref="mobilePhone"
								class="custom-input"
								:rules="[rules.required]"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6 lg8 px-2>
							<span class="pl-2 input-label">E-mail*</span>
							<v-text-field
								v-model="subscriptionForm.email"
								:rules="[rules.email]"
								ref="email"
								class="custom-input"
								outlined
								dense
								color="#204177"
							></v-text-field>
						</v-flex>
						</v-layout>
	</div>
<!--						Fine Ditta Individuale-->


          <div v-else-if="personaFisica">

            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Forma societaria*</span>
                <v-select
                    @change="isDittaIndividuale"
                    :items="formTypes"
                    v-model="subscriptionForm.formType"
                    :rules="[rules.required]"
                    ref="formType"
                    class="custom-input"
                    dense
                    outlined
                    color="#204177"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Codice fiscale</span>
                <v-text-field
                    v-model="subscriptionForm.ownerTaxCode"
                    ref="ownerTaxCode"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Nome*</span>
                <v-text-field
                    v-model="subscriptionForm.firstName"
                    :rules="[rules.required]"
                    ref="firstName"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Cognome*</span>
                <v-text-field
                    v-model="subscriptionForm.lastName"
                    :rules="[rules.required]"
                    ref="lastName"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 px-2 pb-6>
                <span class="subscritpion-item-heading">Residenza</span>
              </v-flex>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Provincia*</span>
                <v-select
                    :items="provinces"
                    v-model="subscriptionForm.province"
                    :rules="[rules.required]"
                    ref="province"
                    class="custom-input"
                    dense
                    outlined
                    color="#204177"
                    :return-object="true"
                    item-text="CDESCRIZIONE"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Città*</span>
                <v-select
                    :items="cities"
                    v-model="subscriptionForm.city"
                    :rules="[rules.required]"
                    ref="city"
                    class="custom-input"
                    dense
                    outlined
                    color="#204177"
                    :return-object="true"
                    item-text="CNOMECOMUNE"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 lg8 px-2>
                <span class="pl-2 input-label">Indirizzo</span>
                <v-text-field
                    v-model="subscriptionForm.address"
                    ref="address"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">CAP</span>
                <v-text-field
                    v-model="subscriptionForm.postalCode"
                    ref="postalCode"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start>
              <v-flex xs12 px-2 pb-6>
                <span class="subscritpion-item-heading">Contatto</span>
              </v-flex>
              <v-flex xs12 sm6 md6 lg4 px-2>
                <span class="pl-2 input-label">Cellulare*</span>
                <v-text-field
                    v-model="subscriptionForm.mobilePhone"
                    ref="mobilePhone"
                    class="custom-input"
                    :rules="[rules.required]"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 lg8 px-2>
                <span class="pl-2 input-label">E-mail*</span>
                <v-text-field
                    v-model="subscriptionForm.email"
                    :rules="[rules.email]"
                    ref="email"
                    class="custom-input"
                    outlined
                    dense
                    color="#204177"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </div>

<!--						Start Società di capitale-->
								<v-flex v-else-if="societàDiCapitali || societàDiPersone">
									<v-layout wrap justify-start>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Forma societaria*</span>
											<v-select
												@change="isDittaIndividuale"
												:items="formTypes"
												v-model="subscriptionForm.formType"
												:rules="[rules.required]"
												ref="formType"
												class="custom-input"
												dense
												outlined
												color="#204177"
											></v-select>
										</v-flex>
									</v-layout>
									<v-layout wrap justify-start>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Ragione Sociale*</span>
											<v-text-field
												v-model="subscriptionForm.businessName"
												:rules="[rules.required]"
												ref="businessName"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">P.IVA</span>
											<v-text-field
												v-model="subscriptionForm.vatNumber"
												ref="vatNumber"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Codice fiscale</span>
											<v-text-field
												v-model="subscriptionForm.ownerTaxCode"
												ref="ownerTaxCode"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
									</v-layout>
									<v-layout wrap justify-start>
										<v-flex xs12 px-2 pb-6>
											<span class="subscritpion-item-heading">Sede legale</span>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Provincia*</span>
											<v-select
												:items="provinces"
												v-model="subscriptionForm.province"
												:rules="[rules.required]"
												ref="province"
												class="custom-input"
												dense
												outlined
												color="#204177"
												:return-object="true"
												item-text="CDESCRIZIONE"
											></v-select>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Città*</span>
											<v-select
												:items="cities"
												v-model="subscriptionForm.city"
												:rules="[rules.required]"
												ref="city"
												class="custom-input"
												dense
												outlined
												color="#204177"
												:return-object="true"
												item-text="CNOMECOMUNE"
											></v-select>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">CAP</span>
											<v-text-field
												v-model="subscriptionForm.postalCode"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Toponimo</span>
											<v-select
												:items="toponimos"
												v-model="subscriptionForm.toponym"
												ref="toponym"
												class="custom-input"
												dense
												outlined
												color="#204177"
											></v-select>
										</v-flex>
										<v-flex xs12 sm6 md6 lg8 px-2>
											<span class="pl-2 input-label">Indirizzo</span>
											<v-text-field
												v-model="subscriptionForm.address"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Numero civico</span>
											<v-text-field
												v-model="subscriptionForm.houseNo"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
									</v-layout>

									<v-layout wrap justify-start>
										<v-flex xs12 px-2 pb-6>
											<span class="subscritpion-item-heading">Contatto</span>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Cellulare*</span>
											<v-text-field
												v-model="subscriptionForm.mobilePhone"
												class="custom-input"
												:rules="[rules.required]"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg8 px-2>
											<span class="pl-2 input-label">E-mail*</span>
											<v-text-field
												v-model="subscriptionForm.email"
												:rules="[rules.email]"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Nome*</span>
											<v-text-field
												v-model="subscriptionForm.firstName"
												:rules="[rules.required]"
												class="custom-input"
												ref="firstName"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>
										<v-flex xs12 sm6 md6 lg4 px-2>
											<span class="pl-2 input-label">Cognome*</span>
											<v-text-field
												v-model="subscriptionForm.lastName"
												:rules="[rules.required]"
												ref="lastName"
												class="custom-input"
												outlined
												dense
												color="#204177"
											></v-text-field>
										</v-flex>

							</v-layout>
	</v-flex>
										<!--						End Società di capitale  -->

<!--					Checkbox	-->
						<v-flex xs12 px-4>
							<v-checkbox
								v-model="subscriptionForm.declare"
								:rules="[rules.required]"
								ref="declare"
								label="L'utente conferma le "
								color="#204177"
								dense
								hide-details
							>	<template v-slot:label>
								<div>
									L'utente conferma per presa visione e accettazione dell'informativa
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<a
												target="_blank"
												href="https://www.consulbrokers.it/azienda/privacy-policy"
												@click.stop
												v-on="on"
											>Condizioni di Utilizzo</a>
										</template>
									</v-tooltip>.
								</div>
							</template></v-checkbox>
						</v-flex>
						<v-flex xs12 px-4>
							<v-checkbox
								v-model="subscriptionForm.accept"
								:rules="[rules.required]"
								ref="accept"
								label="Precontrattuale, Assicurativa e Privacy. "
								color="#204177"
								dense
								hide-details
							>
								<template v-slot:label>
									<div>
										L'utente conferma per presa visione e accettazione dell'informativa
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<a
													target="_blank"
													href="https://www.consulbrokers.it/azienda/privacy-policy"
													@click.stop
													v-on="on"
												>Precontrattuale, Assicurativa e Privacy</a>
											</template>
										</v-tooltip>.
									</div>
								</template>
							</v-checkbox>
						</v-flex>

<!--						Fine Checkbox-->
<!--					</v-layout>-->
				</v-form>
			</v-flex>
			<v-flex xs12 pt-8>
				<v-btn small depressed color="#D1E0FB" @click="subscribe">
					<span class="card-button">INVIA RICHIESTA</span>
				</v-btn>
			</v-flex>
		</v-layout>

	</div>
</template>

<script>
// import * as firebase from "@/plugins/firebase";
import {collection, query, orderBy, getDocs, where} from "firebase/firestore";
import {postForm} from "../../../firebase-remote";
import {db} from "@/firebase";
export default {
	name: "Company",
	props: {

	},
	data() {
		return {
      showForm: false,
			dittaIndividuale: true,
			societàDiCapitali: false,
			societàDiPersone: false,
      personaFisica: false,
			provinces: [],
			citiesOfBirth: [],
			cities: [],
      form: {},
			formTypes: [
				{value: '1', text: 'Ditta individuale'},
				{value: '2', text: "Società di capitale"},
				{value: '3', text: "Società di persone"},
        {value: '4', text: "Persona fisica"}
			],
			toponimos: [
				'Corso',
				'Piazza',
				'Strada',
				'Via',
				'Viale',
				'Altro'
			],
			subscriptionForm: {
				createdAt: '',
				formType: '1',
				businessName: '',
				vatNumber: '',
				ownerTaxCode: '',
				openingDay: '',
				numeroAddetti: '',
				dob: '',
				firstName: '',
				lastName: '',
				countryOfBirth: '',
				provinceOfBirth: null,
				cityOfBirth: null,
				nation: '',
				province: '',
				city: '',
				toponym: '',
				address: '',
				houseNo: '',
				postalCode: '',
				mobilePhone: '',
				email: '',
				declare: false,
				accept: false,
			},
			msg: null,
			showSnackbar: false,
			rules: {
				required: (value) => !!value || "Campo obbligatorio",
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "e-mail non valido";
				},
			},
		}
	},
	watch: {
		'subscriptionForm.provinceOfBirth': {
			handler(val) {
				this.subscriptionForm.cityOfBirth = null;
				this.getCities(val.CCODICE, 'provinceOfBirth');
			}
		},
		'subscriptionForm.province': {
			handler(val) {
				this.subscriptionForm.city = null;
				this.getCities(val.CCODICE, 'province');
			}
		}
	},
	beforeMount() {
		this.getProvinces();
	},
	methods: {
		isDittaIndividuale(){
			this.dittaIndividuale = false
			this.societàDiCapitali = false
			this.societàDiPersone = false
      this.personaFisica = false
			switch (this.subscriptionForm.formType) {
				case '1':
					this.dittaIndividuale = true
					break;
				case '2':
					this.societàDiCapitali = true
					break;
				case '3':
					this.societàDiPersone = true
          break
        case '4':
          this.personaFisica = true
			}

		},
	async getProvinces() {
      let ref = collection(db, 'provinces')
      let q = query(ref, orderBy('CDESCRIZIONE'))
    // eslint-disable-next-line no-mixed-spaces-and-tabs
		  await getDocs(q).then(response => {
			this.provinces = response.docs.map(item => item.data())
		})

	},
	async getCities(item, type) {
    let ref = collection(db, 'communi')
    let q = query(ref, where("CSIGLAPROVINCIA", "==", item))
		await getDocs(q).then(response => {
			switch (type) {
				case 'provinceOfBirth':
					this.citiesOfBirth = response.docs.map(item => item.data())
					break;
				case 'province':
					this.cities = response.docs.map(item => item.data())
					break;
				default:
					break;
			}
		})
	},
		async subscribe() {
			if (this.$refs.form.validate()) {
				if (this.dittaIndividuale) {
					this.subscriptionForm.formType = 'Ditta individuale'
				} else if (this.societàDiCapitali) {
					this.subscriptionForm.formType = 'Società di capitale'
				} else if (this.societàDiPersone) {
          this.subscriptionForm.formType = 'Società di persone'
        } else if (this.personaFisica) {
          this.subscriptionForm.formType = 'Persona fisica'
        }
				this.subscriptionForm.createdAt = new Date()
				this.subscriptionForm.insurance = this.$route.params.slug
				await postForm(this.subscriptionForm)
				this.msg = "Abbonato con successo"
        this.$refs.form.reset()
         this.showSnackbar = true
         await this.$router.push({name: 'confirmation', params: {slug: this.$route.params.slug}})
         } else {
         this.msg = "Si prega di fornire i campi obbligatori";
         this.showSnackbar = true;
      }
      },

	}
}
</script>

<style>
body{
	padding-left: 10px;
	padding-right: 10px;
}
.subscritpion-item-heading {
	font-family: 'Montserrat', sans-serif;
	color: #204177;
	font-size: 26px;
	font-weight: 800;
}

.input-label {
	font-family: 'Montserrat', sans-serif;
	color: #204177;
	font-size: 16px;
	font-weight: 500;
}

.custom-input > .v-input__control > .v-input__slot {
	border: 1px solid #20417725 !important;
	box-shadow: 0px 3px 17px #2041771c !important;
}
</style>
